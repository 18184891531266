import logo from './logo_transparent.png';
import './App.css';

function App() {
  return (
    <div className="App">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Kehitämme verkkosivujamme parhaillaan.
        </p>
    </div>
  );
}

export default App;
